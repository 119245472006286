
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddQuickInquiryModal from "@/views/task-management/quick-inquiry/AddQuickInquiryModal.vue";
import QuickInquiryImportModal from "@/views/task-management/quick-inquiry/ImportModal.vue";
import FilterDropdwon from "@/views/task-management/quick-inquiry/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface QuickInquiry {
  id: number;
  name: string;
  description: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "quick-inquiry",
  components: {
    MBDatatable,
    FilterDropdwon,
    AddQuickInquiryModal,
    QuickInquiryImportModal,
  },
  setup() {
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<QuickInquiry>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "request_date", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const { t } = useI18n();
    const tableHeader = ref([
      // {
      //   name: t("quickInquiry.inquiryId"),
      //   key: "id",
      //   sortable: true,
      // },
      {
        name: t("quickInquiry.requestedBy"),
        key: "requested_by",
        sortable: false,
      },
      {
        name: t("quickInquiry.requestDate"),
        key: "request_date",
        sortable: true,
      },
      {
        name: t("quickInquiry.taskOwner"),
        key: "task_owner",
        sortable: false,
      },
      {
        name: t("quickInquiry.request"),
        key: "request",
        sortable: false,
      },
      {
        name: t("quickInquiry.quote"),
        key: "quote",
        sortable: false,
      },
      {
        name: t("quickInquiry.quoteAmount"),
        key: "quote_amount",
        sortable: false,
        align: "right",
      },
      {
        name: t("quickInquiry.internalComment"),
        key: "internal_comment",
        sortable: false,
      },
      {
        name: t("quickInquiry.closeStatus"),
        key: "close_status",
        sortable: false,
      },
      // {
      //   name: t("quickInquiry.importance"),
      //   key: "importance",
      //   sortable: false,
      // },
      // {
      //   name: t("quickInquiry.commentTagging"),
      //   key: "comment_tagging",
      //   sortable: false,
      // },
      {
        name: "", // t("common.actions")
        key: "actions",
        sortable: false,
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getQuickInquiryList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
      setDocTitle();
    });

    const getQuickInquiryList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiTaskManagement.getQuickInquiryList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          if (item == "comment_tagging") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "find_in_set",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getQuickInquiryList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getQuickInquiryList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getQuickInquiryList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getQuickInquiryList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getQuickInquiryList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getQuickInquiryList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getQuickInquiryList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const setDocTitle = () => {
      document.title = "MagicBean SaaS Distribution";
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getQuickInquiryList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      updateList,
    };
  },
});
