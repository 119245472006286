import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_quick_inquiry_import",
  ref: "quickInquiryImportModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  id: "kt_accounts_export_close",
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body scroll-y mx-5 mx-xl-15 my-7" }
const _hoisted_9 = { class: "el-upload__tip text-red" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("quickInquiry.importQuickQuiry")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_upload, {
            action: _ctx.uploadUrl,
            headers: _ctx.uploadHeaders,
            limit: 1,
            accept: ".xls,.xlsx",
            "auto-upload": true,
            "on-success": _ctx.handleUploadSuccess,
            ref: "uploadRef"
          }, {
            tip: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t("quickInquiry.uploadTip")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                icon: _ctx.Upload
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("quickInquiry.clickToUpload")), 1)
                ]),
                _: 1
              }, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["action", "headers", "on-success"])
        ])
      ])
    ])
  ], 512))
}