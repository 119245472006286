
import { ApiBase } from "@/core/api";
import { defineComponent, onBeforeMount, ref } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Filter {
  task_owner: string;
  requested_by: string;
  request_date: string;
  importance: string;
  close_status: string;
  task_source: string;
  comment_tagging: Array<string>;
}

export default defineComponent({
  name: "quick-inquiry-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      task_owner: "",
      requested_by: "",
      request_date: "",
      importance: "",
      close_status: "",
      task_source: "",
      comment_tagging: [],
    });
    const taskOwnerOptions = ref([]);
    const taskOwnerLoading = ref<boolean>(false);
    const requestedByOptions = ref([]);
    const requestedByLoading = ref<boolean>(false);
    const commentTaggingOptions = ref([]);
    const commentTaggingLoading = ref<boolean>(false);
    const closeStatusOptions = ref([]);

    const { t } = useI18n();
    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "quick_inquiry_close_status",
          "quick_inquiry_comment_tagging",
        ],
      });
      if (data.code == 0) {
        commentTaggingOptions.value =
          data.data.quick_inquiry_comment_tagging.items;
        closeStatusOptions.value = data.data.quick_inquiry_close_status.items;
      }
    };

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (taskOwnerLoading.value = true)
            : (taskOwnerLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          taskOwnerOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData({
        ...params,
        status: 0,
      });
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchTaskOwnerItems = (query) => {
      debounceUserSearch(0, query);
    };

    const switchAccountType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (requestedByLoading.value = true)
            : (requestedByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchAccountValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          requestedByOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getAccountSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchAccountType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      switchAccountType(type, false);
      if (data.code == 0) {
        switchAccountValue(type, data.data);
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchRequestedByItems = (query) => {
      debounceAccountSearch(0, query);
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onBeforeMount(() => {
      getDropdownOptions();
    });

    return {
      data,
      formRef,
      taskOwnerOptions,
      taskOwnerLoading,
      requestedByOptions,
      requestedByLoading,
      commentTaggingOptions,
      commentTaggingLoading,
      closeStatusOptions,
      t,
      searchTaskOwnerItems,
      searchRequestedByItems,
      submit,
      handleReset,
    };
  },
});
